<template>
  <el-dialog
    class="modal-mobile-full"
    title="Warning"
    :visible="true"
    width="100%"
    center
    fullscreen
    append-to-body
  >
    <template slot="title">
      <div style="color: black; margin-right: -20px">Фильтры</div>
    </template>
    <div>
      <filter-section :option="statusMobile" title-section="Статус" />
      <filter-section :option="statusMobile" title-section="Дата тайм слотов" />
      <filter-section :option="statusMobile" title-section="Культура" />
      <filter-section :option="statusMobile" title-section="Поставщик" />
    </div>
    <!--    Секция статуса-->

    <div slot="footer" class="dialog-footer">
      <iq-button size="fullWidth" @click="centerDialogVisible = false">
        Готово
      </iq-button>
    </div>
  </el-dialog>
</template>

<script>
import { statusMobile } from '@/views/manager/components/mobile/components/data'
import FilterSection from '@/views/manager/components/mobile/components/FilterSection'
import IqButton from '@/UI/buttons/IqButton'

export default {
  components: { IqButton, FilterSection },
  data() {
    return {
      centerDialogVisible: false,
      statusMobile,
    }
  },
}
</script>

<style lang="sass">
.modal-mobile-full
  .el-dialog
    border-radius: 0px!important
    background: $color-white
    &__header
      background: white!important
      border-radius: 10px 10px 0 0
      padding: 12px 50px 15px 15px
      color: $color-white
      position: relative
      font-weight: 500
      font-size: 18px
      line-height: 26px
      font-feature-settings: 'tnum' on, 'lnum' on
      .header__close
        position: absolute
        top: 50%
        right: 10px
        transform: translateY(-50%)
        transition: $transition-default
        cursor: pointer
        &:hover
          transform: translateY(-50%) scale(1.2)
    &__body
      padding: 20px 16px
      word-break: break-word
      height: 85%
      overflow-y: scroll
    &__footer
      width: 100%
      box-shadow: 0px -4px 4px rgba(225, 225, 225, 0.25)

    .el-form-item
      margin-bottom: 20px
    .el-form-item__label
      text-align: left
      line-height: 32px
    +to(550px)
      .el-form-item__label
        width: 100% !important
      &:before
        font-size: 0
    .el-form-item__content
      line-height: 32px
</style>
